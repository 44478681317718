* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "GDS Transport", Arial, sans-serif;
}

.container {
  margin: 0 auto;
  max-width: 960px;
  width: 100%;
}

.passport-form-wrapper {
  border: 1px solid #b1b4b6;
  border-radius: 10px;
  padding: 50px 40px;
  min-width: 70%;
  margin-top: 50px;
}

.passport-form-wrapper .input-wrapper {
  display: flex;
  margin-bottom: 40px;
}

.datepicker-main {
  position: relative;
}

.input-wrapper .cus-input,
.datepicker-main .datepicker-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #b1b4b6;
  font-size: 19px;
}

.input-wrapper .check-btn {
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.1875;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: max-content;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  padding: 8px 10px 7px;
  border: 2px solid transparent;
  border-radius: 0;
  color: #fff;
  background-color: #00703c;
  box-shadow: 0 2px 0 #002d18;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  -webkit-appearance: none;
  margin-left: 20px;
}

/* .pass-wrapper{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
} */
.datepicker-main {
  margin-bottom: 40px;
}

.datepicker-main .react-datepicker-wrapper {
  width: 100%;
}

.datepicker-main .datepicker-input {
  width: 100%;
}

.submit-btn-sec .submit-btn {
  width: 100%;
  background: #0d6efd;
  padding: 14.5px 10px;
  color: white;
  border-radius: 5px;
  border: none;
}

.title-sec h1 {
  font-family: "nta", Arial, sans-serif;
  font-weight: 400;
  text-transform: none;
  font-size: 50px;
  line-height: 1.09375;
  font-weight: bold;
  margin: 10px 0 15px 0;
}

.title-sec p {
  font-size: 19px;
  line-height: 25px;
}

.passport-form-wrapper .form-wrapper {
  max-width: 60%;
}

.datepicker-main .calander-ic {
  width: 20px;
  position: absolute;
  top: 57%;
  transform: translateY(-50%);
  right: 15px;
  z-index: 0;
}

.datepicker-main .datepicker-input {
  padding-right: 50px;
  background: transparent;
  z-index: 1;
  position: relative;
}

.react-datepicker__input-container,
.datepicker-main .new-datepicker-input {
  background: white;
}

.logo-sec {
  display: flex;
  align-items: center;
  gap: 7px;
  min-width: 319px;
  margin-bottom: 10px;
}

.logo-sec h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 37px;
}

.main-header {
  font-weight: 400;
  font-size: 14px;
  font-size: .875rem;
  line-height: 1.14286;
  border-bottom: 10px solid #fff;
  color: #fff;
  background: #0b0c0c;
  min-height: 60px;
  display: flex;
  align-items: center;
}

.header-wrapper {
  display: flex;
  align-items: center;
}

.header-wrapper a {
  display: inline-block;
  font-family: "GDS Transport", arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 1.25;
  color: white;
  margin-bottom: 10px;
}

.header-wrapper a:hover {
  text-decoration: underline !important;
}

.br-bottom {
  margin-bottom: -10px;
  padding-top: 10px;
  border-bottom: 10px solid #1d70b8;
  position: relative;
}

.datepicker-main .new-datepicker-input {
  min-width: 100% !important;
  font-size: 19px;
}

.datepicker-main .new-datepicker-input label,
.input-wrapper .cus-input::placeholder {
  color: #b1b4b6;
  font-size: 16px;
}