@media (max-width: 1023px) {
    .br-bottom {
        padding: 10px 15px 0;
    }
    .header-wrapper a{
        font-size: 1.4rem;
    }
    .logo-sec h2 {
        font-size: 28px;
        line-height: 35px;
    }
    .title-sec h1 {
        font-size: 35px;
        line-height: 1;
    }
    .container {
        padding: 0 15px;
    }
}

@media (max-width: 767px) {
    .header-wrapper {
        flex-direction: column;
        align-items: self-start;
    }
    .main-header{
        padding: 10px 0;
    }
    .br-bottom {
        margin-bottom: -20px;
    }
    .title-sec h1 {
        font-size: 30px;
        line-height: 35px;
    }
    .passport-form-wrapper {
        padding: 25px 20px;
        min-width: 100%;
        margin-top: 40px;
    }
    .passport-form-wrapper .form-wrapper {
        max-width: 100%;
    }
    .passport-form-wrapper .input-wrapper {
        margin-bottom: 30px;
    }
}